<template>
    <div class="container h-100 my-2 compte-creation">
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                <router-link :to="{ name: 'Home' }" class="d-block mb-3"><font-awesome-icon class="mr-1" :icon="['fal', 'arrow-left']"/> {{ $t('global.retour') }}</router-link>
                <div class="box">
                <h2>{{ $t("compte.creation_compte") }}</h2>
                    <form @submit.prevent="validForm">

                        <!-- Nom -->
                        <div class="form-group">
                            <label for="name">{{ $t('compte.nom') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.nom')" v-model="name" id="name" required>
                            </div>
                        </div>

                        <!-- Prenom -->
                        <div class="form-group">
                            <label for="firstname">{{ $t('compte.prenom') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.prenom')" v-model="firstname" id="firstname" required>
                            </div>
                        </div>

                        <!-- Email -->
                        <div class="form-group">
                            <label for="mail">{{ $t('compte.email') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="mail" :placeholder="$t('compte.email')" v-model="mail" id="mail" required>
                            </div>
                        </div>

                        <!-- Mot de passe -->
                        <div class="form-group">
                            <label for="password">{{ $t('compte.password') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="password" :placeholder="$t('compte.password')" v-model="password" id="password" required>
                            </div>
                        </div>

                        <!-- Mot de passe confirmation -->
                        <div class="form-group">
                            <label for="password_confirmation">{{ $t('compte.password_confirmation') }}<sup>*</sup></label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="password" :placeholder="$t('compte.password_confirmation')" v-model="password_confirmation" id="password_confirmation" required>
                            </div>
                        </div>

                        <!-- Téléphone -->
                        <div class="form-group phone-field">
                            <label for="telephone">{{ $t('compte.telephone') }}</label>

                                <div class="row no-gutters">
                                    <div class="col-auto pr-2 phone">
                                        <phoneInput v-model="phone"></phoneInput>
                                    </div>
                                    <div class="col pr-2">
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text">{{ phone.phone_indicatif }}</div>
                                            </div>
                                            <input type="text" class="form-control" v-model="phone.phone_numero">
                                        </div>
                                    </div>
                                </div>
                        </div>

                        <!-- Code parrain -->
                        <div class="form-group code-parrain-field">
                            <label for="code_parrain">{{ $t('compte.code_parrain') }}</label>

                            <div class="input-group">
                                <input autocomplete="off" class="form-control" type="text" :placeholder="$t('compte.code_parrain')" v-model="code_parrain" id="code_parrain" v-mask="'UUU#'">
                            </div>
                        </div>

                        <!-- Message d'erreur si besoin -->
                        <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                        <!-- Bouton de validation du formulaire -->
                        <button type="submit" v-on:click="checkForm" class="mt-4 btn btn-primary btn-block rounded-pill">
                            {{ $t('global.ajouter') }}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'


    import Config from "@/mixins/Config.js";
    import User from "@/mixins/User.js";
    import Tools from "@/mixins/Tools.js";
    import Navigation from "@/mixins/Navigation.js";
    import Tiers from "@/mixins/Tiers.js";
    import router from "@/router";

    import Countries from 'GroomyRoot/assets/lang/countries/countries'
    import Indicatif from 'GroomyRoot/assets/lang/countries/indicatif'

    export default {
        name: "CompteCreation",
        mixins: [Config, User, Tools, Navigation, Tiers],
        props: {
            cp: String,
        },
        data () {
            return {
                name: null,
                firstname: null,
                mail: null,
                password: null,
                password_confirmation: null,
                ad1: null,
                ad2: null,
                code_postal: null,
                ville: null,
                datenaissance: null,
                indicatifs: {},
                countries: {},
                countries_iso: {},
                phone: {
                    phone_indicatif: '+33',
                    phone_numero: '',
                    phone_country: "FR"
                },
                code_parrain: null,
                message_erreur_code: ""
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                const actual_date = new Date()
                this.datenaissance = actual_date.toDateInputValue()

                this.indicatifs = Indicatif
                this.countries = Countries[Vue.i18n.locale()].label
                this.countries_iso = Countries[Vue.i18n.locale()].iso_3

                this.code_parrain = this.cp;
            },

            async validForm() {
                let user = {
                    name: this.name,
                    firstname: this.firstname,
                    mail: this.mail,
                    password: this.password,
                    password_confirmation: this.password_confirmation,
                    code_parrain: this.code_parrain,
                    indicatif: this.phone.phone_indicatif,
                    phone: this.phone.phone_numero,
                    country: this.phone.phone_country,
                }

                let res = await this.createUser(user, 'client_area')
                if(res.code_retour) {
                    // this.$cache.user_id = res.retour.id
                    await this.setConfig("user_id", res.retour.id)

                    const result_login = await this.checkLogin(this.mail, this.password)
                    if(result_login){
                        this.$router.push({ name: 'EmailValidation' })
                    }
                }
                else {
                    this.message_erreur_code = res.retour
                }
            },
            indicatif(country)
            {
                this.phone.phone_indicatif = this.indicatifs[country]
                this.phone.phone_country = country
            }
        },
        components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
        }
    };
</script>
